import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/staffai/components/images/a.png");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/staffai/components/images/b.png");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/staffai/components/images/c.png");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/staffai/components/images/d.png");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/staffai/components/images/lipread.png");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/staffai/components/images/natural.png");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/staffai/components/images/signlang.png");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/staffai/components/images/voice.png");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/staffai/components/ui/icons.tsx");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/node_modules/.pnpm/next@14.1.2_@babel+core@7.24.3_react-dom@18.2.0_react@18.2.0_sass@1.72.0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/node_modules/.pnpm/next@14.1.2_@babel+core@7.24.3_react-dom@18.2.0_react@18.2.0_sass@1.72.0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/node_modules/.pnpm/next@14.1.2_@babel+core@7.24.3_react-dom@18.2.0_react@18.2.0_sass@1.72.0/node_modules/next/dist/shared/lib/app-router-context.shared-runtime.js");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/node_modules/.pnpm/next@14.1.2_@babel+core@7.24.3_react-dom@18.2.0_react@18.2.0_sass@1.72.0/node_modules/next/dist/shared/lib/hooks-client-context.shared-runtime.js");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/node_modules/.pnpm/next@14.1.2_@babel+core@7.24.3_react-dom@18.2.0_react@18.2.0_sass@1.72.0/node_modules/next/dist/shared/lib/server-inserted-html.shared-runtime.js")